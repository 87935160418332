<template src="./FirstAdmissionC.html">
</template>

<script>
import ArrobaIdIcon from '@/components/Icons/ArrobaIdIcon';

export default {
  name: 'first-admission-c',
  data () {
    return {
      atId: null
    }
  },
  components: { ArrobaIdIcon },
  created () {
    this.atId = sessionStorage.getItem('atId');
  },
  methods: {
    redirectTo(route) {
      sessionStorage.removeItem('atId');
      this.$router.push({path: `${route}`})
    }
  }
}
</script>

<style lang="scss" scoped src="./FirstAdmissionC.scss">
</style>
